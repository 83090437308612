.container {
  position: fixed;
  width: 383px;
  filter: drop-shadow(0px 10px 20px rgba(32, 37, 38, 0.1))
    drop-shadow(0px 20px 50px rgba(32, 37, 38, 0.1));
  background: #ffffff;
  border-radius: 12px;
  transition: 0.1s;
  padding: 11px 24px;
  &_passive {
    opacity: 0;
    pointer-events: none;
  }
  &_title {
    margin-bottom: 11px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #464255;
    > span {
      cursor: pointer;
    }
  }
  &_content {
    display: flex;
    margin-bottom: 16px;
    :global {
      .ant-select {
        width: 100%;
        .ant-select-selector {
          border: 1px solid #e5e7eb;
          border-radius: 6px;
        }
      }
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    > button {
      flex: 1;
      border-radius: 50px;
      height: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      background: #00a389;
      color: #ffffff;
      border: none;
      &:hover,
      &:focus {
        background: #00a389;
        color: #ffffff;
      }
      &:first-child {
        background: #e53535;
      }
    }
  }
}
