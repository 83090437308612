.container {
  width: 100%;
  height: calc(100% - 88px);
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    height: 16px;
  }
  > svg {
    width: 100%;
    // margin: 0 auto;
    // flex: none;
    // position: absolute;
    // transform: translate(-50%, -50%);
    transition: 0.1s;
    top: 0%;
    left: 0%;
  }
}
