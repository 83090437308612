.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  &_item {
    display: flex;
    flex-direction: column;
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #000000;
    }
    &_colors {
      display: flex;
      gap: 12px;
      > span {
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: block;
        transition: 0.3s;
        &:hover {
          transform: scale(1.5);
        }
      }
      &_active {
        transform: scale(1.5);
      }
    }
    &_slider {
      display: flex;
      align-items: center;
      gap: 5px;
      > span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 20px;
        height: 20px;
        background: #c5c5c5;
        border-radius: 25px;
        cursor: pointer;
        user-select: none;
      }
      :global {
        .ant-slider {
          width: 260px;
          &:hover {
            .ant-slider-handle {
              border: 4px solid #00a389;
              &:focus,
              &:hover {
                border: 4px solid #00a389;
              }
            }
          }
          .ant-slider-rail {
            background: #c5c5c5;
            height: 2px;
          }
          .ant-slider-track {
            background: #c5c5c5;
            height: 2px;
          }
          .ant-slider-handle {
            border: 4px solid #00a389;
            &:focus,
            &:hover {
              border: 4px solid #00a389;
            }
          }
        }
      }
    }
  }
  &_submit {
    width: 86px;
    height: 36px;
    background: #00a389;
    border-radius: 8px;
    border: none;
    &:hover,
    &:focus {
      background: #00a389;
    }
  }
}
