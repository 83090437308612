.container {
  :global {
    .ant-modal {
      width: 90% !important;
      height: 90%;
      top: 5%;
      .ant-modal-content {
        border-radius: 12px;
        height: 100%;
        .ant-modal-header {
          border-radius: 12px 12px 0 0;
          height: 60px;
          .ant-modal-title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #464255;
          }
        }
        .ant-modal-body {
          padding: 0;
          height: calc(100% - 60px);
        }
      }
    }
  }
}
