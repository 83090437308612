@import "../../App.scss";

.buildings {
  max-height: calc(100vh - 100px);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  overflow-y: auto;
}
.buildingsCard {
  width: rem(305);
  height: rem(236);
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_check {
    display: flex;
    padding: 20px 20px 0 20px;
    width: 100% !important;
    justify-content: space-between;
    &_icon {
      width: rem(61);
      height: rem(61);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--lightGreen);
    }
    button {
      width: rem(50) !important;
      height: rem(26) !important;
      .ant-switch-handle {
        margin-top: rem(1.5);
      }
    }
  }
  button {
    width: 100%;
    height: rem(56);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(26);
  }
  h3 {
    font-weight: 700;
    font-size: rem(32);
    line-height: rem(42);
    color: var(--darkSilver);
    margin-left: rem(20);
  }
}
